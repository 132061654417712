<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
        >
          {{ $t("view.permissions") }}
        </h4>

        <div v-if="permissionLoading" class="text-center">
          <span
            class="spinner-grow spinner-grow-sm mr-1 text-center"
            role="status"
            aria-hidden="true"
          >
          </span>
        </div>

        <div v-if="!permissionLoading">
          <div
            class="form-group col-10"
            v-for="(permission, index) in permissions"
            :key="index"
          >
            <label
              class="
                mb-3
                mt-3
                control-label
                badge
                rounded-pill
                badge-md badge-light-primary
              "
              style="padding: 10px"
            >
              <span :class="$i18n.locale == 'ar' ? 'font-size-14' : ''">
                {{ findText(index) }}
              </span>
            </label>

            <div class="row col-12 text-center" style="margin-left: 50px">
              <div
                v-for="(item, idx) in permission.length"
                :key="idx"
                class="custom-control custom-switch col-3"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  @change="isChecked($event, permission[idx].id)"
                  :id="permission[idx].id"
                  :value="permission[idx].id"
                  :checked="
                    row.permissions_ids.indexOf(permission[idx].id) !== -1
                      ? true
                      : false
                  "
                />
                <label
                  class="custom-control-label"
                  style="font-size: 15px; margin-left: -40px"
                  :for="permission[idx].id"
                >
                  <span>
                    <!-- v-if="(locale == 'ar')" -->
                    <span
                      v-if="permission[idx].name == 'view'"
                      :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                    >
                      {{ $t("role.view") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'add'"
                      :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                    >
                      {{ $t("role.add") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'show'"
                      :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                    >
                      {{ $t("role.show") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'edit'"
                      :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                    >
                      {{ $t("role.edit") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'delete'"
                      :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                    >
                      {{ $t("role.delete") }}
                    </span>
                  </span>
                  <!-- <span v-else>{{ permission[idx].name }}</span> -->
                </label>
              </div>
            </div>
            <p><br /></p>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "FormPermissions",
  props: ["permissions_ids"],
  data() {
    return {
      auth: {
        id_token: "",
      },
      row: {
        permissions_ids: this.$props.permissions_ids,
      },
      permissions: [],
      permissionLoading: false,
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }

    this.fetchPermissions();
  },
  methods: {
    // is Checked
    isChecked($event, id) {
      if ($event.target.checked) {
        this.row.permissions_ids.push(id);
      } else {
        this.row.permissions_ids.splice(
          this.row.permissions_ids.indexOf(id),
          1
        );
      }

      this.$emit("permissionChange", this.row.permissions_ids);
    },

    fetchPermissions() {
      this.permissionLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: "/permissions",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.permissionLoading = false;
          this.permissions = res.data.data.rows;
        })
        .catch((err) => {
          this.permissionLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    findText(text) {
      return this.$t("nav." + text);
    },
  },
};
</script>
