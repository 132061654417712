<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.roles')"
        :pgIcon="'bx bx-lock'"
        :refs="refs"
        :pgCurrent="pgCurrent"
        :addNew="false"
      >
      </Breadcrumb>

      <div class="row">
        <Loading v-if="pgLoading"> </Loading>

        <div v-if="!pgLoading" class="col-8 col-sm-12">
          <FormSingleLang
            :label="$t('view.role_info')"
            :labelTitle="$t('view.role_name')"
            :hasTitle="true"
            :title="row.title"
            :no_space="true"
            v-on:formChange="handleFormChange"
          >
          </FormSingleLang>

          <FormPermissions
            :permissions_ids="row.permissions_ids"
            v-on:permissionChange="handlePermissionChange"
          >
          </FormPermissions>
        </div>

        <div v-if="!pgLoading" class="col-4 col-sm-12">
          <CardStatus
            :status="row.status"
            v-on:statusChange="handleStatusChange"
          >
          </CardStatus>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import CardStatus from "@/components/CardStatus";
import Breadcrumb from "@/components/Breadcrumb";
import Loading from "@/components/Loading";
import Buttons from "@/components/Buttons";
import FormSingleLang from "@/components/FormSingleLang";
import FormPermissions from "@/components/FormPermissions";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default {
  name: "EditOrNew",
  components: {
    CardStatus,
    Breadcrumb,
    Loading,
    Buttons,
    FormSingleLang,
    FormPermissions,
  },
  data() {
    return {
      // auth
      auth: {
        role: "",
        id_token: "",
      },

      // row
      row: {
        title: "",
        permissions_ids: [],

        status: 1,
      },

      msgCurrent: this.$route.params.id ? "Updated" : "Created",
      btnCurrent: this.$route.params.id ? "Update" : "Create",
      methodType: this.$route.params.id ? "PUT" : "POST",
      pgLoading: this.$route.params.id ? true : false,
      pgCurrent: this.$route.params.id ? "Edit" : "Add New",
      btnLoading: false,

      refs: "roles",
    };
  },
  created() {
    this.auth.id_token = localStorage.getItem("id_token");

    this.auth.role = localStorage.getItem("role_key");

    this.row.country_id = localStorage.getItem("country_id");

    if (this.$route.params.id) {
      this.fetchRow();
    }
    setCurrentPageTitle(this.$t("nav.roles"), "bx bx-lock");
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.title = res.data.row.title;
          this.row.permissions_ids = res.data.row.permissions_ids;

          this.row.status = res.data.row.status;
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: this.$route.params.id
          ? this.refs + "/" + this.$route.params.id
          : this.refs,
        method: this.methodType,
        data: {
          name: this.row.title,
          permissions_ids: this.row.permissions_ids,
          country_id: this.row.country_id,
          status: this.row.status,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message:
              this.btnCurrent == "Update"
                ? "تم التعديل بنجاح"
                : "تم الإضافة بنجاح",
          });

          this.$router.push({ name: this.refs });
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("user_image");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: this.refs });
      }
    },

    // handel Changes from child components
    handleFormChange(event) {
      this.row.title = event.title;
    },

    handlePermissionChange(event) {
      this.row.permissions_ids = event;
    },

    handleStatusChange(event) {
      this.row.status = event.status;
    },
  },
};
</script>
